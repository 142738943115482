import React from 'react'
import { MdEmail } from "react-icons/md";

const Navbar = React.lazy(() => import('../common/navbar/Navbar'))
const Footer = React.lazy(() => import('../common/Footer'))

const Layout = ({
  children,
  pathname = '',
  hideNavbar = false,
  hideFooter = false,
}) => {
  // Maintenance Page
  // return(
  //   <div className="container w-full h-screen flex flex-col gap-2 justify-center items-center">
  //       <img src={'/images/maintenance.jpg'} alt="" className="justify-center items-center w-full md:w-1/2"/>
  //   </div>
  // )
  return (
    <>
      {!hideNavbar && <Navbar pathname={pathname} />}
      <main className="pt-24">
        {children}
        <div className='flex justify-end sticky bottom-0 z-fixed'>
          <a 
            // target='__blank' 
            href={`mailto:contactus@bifarma.co.id`}
            className='block p-3 mb-8 mr-4 bg-dark-blue rounded-full border border-dark-green hover:bg-normal-blue hover:scale-[1.05] transition-all'
          >
            <MdEmail size={36} className='text-white'/>
          </a>
        </div>
      </main>
      {!hideFooter && <Footer />}
    </>
  )
}

export default Layout
